
<template>
    <div  id="bpjs">
    <b-container fluid>
    <div v-show="isLoading">
        <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
      <form>
        <b-row>
          <b-col lg="12">
            <iq-card body-class="p-0">
              <template v-slot:body>
              </template>
              <div class="bg-white border px-3 py-2">
                <h5 class="card-title text-dark font-weight-bold m-0 p-0">Monitoring Klaim BPJS</h5>
              </div>
            </iq-card>
          </b-col>
          <b-col lg="12">
              <tab-content id="pills-tabContent-2">
                <tab-content-item :active="true" id="kunjunganbpjs" >
                    <div class="bg-white border px-3 py-2 d-flex flex-row">
                        <div class="px-1 col-lg-2 col-10">
                          <flat-pickr :config="config" v-model="search.tanggal_awal" value="" @on-change="convertDate($event, 'tanggalawal')" placeholder="[ Tanggal ]" class="w-100 calender-small custom-flat-pickr m-0">
                          </flat-pickr>
                        </div>
                        <div class="px-1 col-lg-2 col-10">
                            <multiselect v-model="selected.jenis_pelayanan" @input="flag" :options="options.jenis_pelayanan" track-by="value" :allow-empty="false" label="name" :show-labels="false" placeholder="[ Jenis Pelayanan ]" :searchable="true">
                            </multiselect>
                        </div>
                        <div class="px-1 col-lg-2 col-10">
                            <multiselect v-model="selected.status" @input="flag" :options="options.status" track-by="value" :allow-empty="false" label="name" :show-labels="false" placeholder="[ Status Klaim ]" :searchable="true">
                            </multiselect>
                        </div>
                        <div class="px-1 col-lg-2 col-10">
                            <b-button variant="primary" class="rounded-1" size="sm" @click="cari()"><i class="fas fa-search"></i> Cari</b-button>
                        </div>
                    </div>
                    <div class="table bg-white mt-2 ">
                          <b-table class="mb-0" ref="table" :busy="table.isBusy" :items="table.items" :fields="table.fields" :select-mode="table.selectMode" :per-page="table.perPage"
                              :current-page="table.currentPage" stacked="md"
                                bordered hover small show-empty selectable>
                                <template #table-busy>
                                    <div class="text-center my-2">
                                    <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                                    <strong class="text-dark">Memuat Data...</strong>
                                    </div>
                                </template>
                                <template #cell(index)="data">
                                    <span>{{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}</span>
                                </template>
                          </b-table>
                        </div>
                        <div class="table-pagination bg-white border px-3 py-2 d-flex flex-row">
                            <div>
                                <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage" size="sm" class="my-0"></b-pagination>
                            </div>
                            <div @click="refreshTable" class="my-cursor">
                                <i class="fas fa-sync"></i>
                            </div>
                            <div class="ml-auto">
                                <p class="m-0 p-0 text-secondary">Menampilkan {{ table.totalRows }} baris</p>
                            </div>
                      </div>
                </tab-content-item>
              </tab-content>
          </b-col>
        </b-row>
      </form>
    </b-container>
    </div>
</template>
<script>
import { xray } from '../../../../../config/pluginInit.js'
import Loader from '../../../../../components/xray/loader/Loader'
import Services from '../../../../../services/fatmahost/connected/index.js'
import Multiselect from 'vue-multiselect'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'BPJS',
  components: {
    Loader,
    Multiselect,
    flatPickr
  },
  data () {
    return {
      isLoading: false,
      table: {
        isBusy: false,
        items1: this.list,
        items: [
          { noSep: '123456789', tglSep: '2024-07-31', jnsPelayanan: 'Rawat Inap', noKartu: '987654321', nama: 'John Doe', poli: 'Poli Umum', diagnosa: 'Flu', noRujukan: '11223344' },
          { noSep: '987654321', tglSep: '2024-07-30', jnsPelayanan: 'Rawat Jalan', noKartu: '123456789', nama: 'Jane Smith', poli: 'Poli Anak', diagnosa: 'Demam', noRujukan: '44332211' },
          { noSep: '123456789', tglSep: '2024-07-31', jnsPelayanan: 'Rawat Inap', noKartu: '987654321', nama: 'John Doe', poli: 'Poli Umum', diagnosa: 'Flu', noRujukan: '11223344' },
          { noSep: '987654321', tglSep: '2024-07-30', jnsPelayanan: 'Rawat Jalan', noKartu: '123456789', nama: 'Jane Smith', poli: 'Poli Anak', diagnosa: 'Demam', noRujukan: '44332211' },
          { noSep: '123456789', tglSep: '2024-07-31', jnsPelayanan: 'Rawat Inap', noKartu: '987654321', nama: 'John Doe', poli: 'Poli Umum', diagnosa: 'Flu', noRujukan: '11223344' },
          { noSep: '987654321', tglSep: '2024-07-30', jnsPelayanan: 'Rawat Jalan', noKartu: '123456789', nama: 'Jane Smith', poli: 'Poli Anak', diagnosa: 'Demam', noRujukan: '44332211' },
          { noSep: '123456789', tglSep: '2024-07-31', jnsPelayanan: 'Rawat Inap', noKartu: '987654321', nama: 'John Doe', poli: 'Poli Umum', diagnosa: 'Flu', noRujukan: '11223344' },
          { noSep: '987654321', tglSep: '2024-07-30', jnsPelayanan: 'Rawat Jalan', noKartu: '123456789', nama: 'Jane Smith', poli: 'Poli Anak', diagnosa: 'Demam', noRujukan: '44332211' },
          { noSep: '123456789', tglSep: '2024-07-31', jnsPelayanan: 'Rawat Inap', noKartu: '987654321', nama: 'John Doe', poli: 'Poli Umum', diagnosa: 'Flu', noRujukan: '11223344' },
          { noSep: '987654321', tglSep: '2024-07-30', jnsPelayanan: 'Rawat Jalan', noKartu: '123456789', nama: 'Jane Smith', poli: 'Poli Anak', diagnosa: 'Demam', noRujukan: '44332211' }
        ],
        fields: [
          { label: 'NO.', key: 'index', class: 'text-lg-center' },
          { label: 'NOMOR SEP', key: 'noSep' },
          { label: 'No FPK', key: 'noFPK' },
          { label: 'Tgl. SEP', key: 'tglSep' },
          { label: 'NO. KARTU', key: 'peserta.noKartu' },
          { label: 'NAMA', key: 'peserta.nama' },
          { label: 'POLI', key: 'poli' },
          { label: 'NORM', key: 'peserta.noMR' },
          { label: 'KODE INACBG', key: 'Inacbg.kode' },
          { label: 'NAMA INACBG', key: 'Inacbg.nama' },
          { label: 'AKSI', key: 'aksi', class: 'text-center' }
        ],
        currentPage: 1,
        perPage: 10,
        totalRows: 0,
        selectMode: 'single',
        condition: ''
      },
      formRegistrasi: {
        nik: '',
        nama: '',
        jk: null,
        tgl_lahir: '',
        status_nikah: '',
        pendidikan: '',
        pekerjaan: '',
        alamat: '',
        alamat_kel: '',
        alamat_kec: '',
        alamat_prov: '',
        alamat_kota: '',
        no_hp: ''
      },
      view: {
        nik: '',
        nama: '',
        jk: null,
        tgl_lahir: '',
        status_nikah: '',
        pendidikan: '',
        pekerjaan: '',
        alamat: '',
        alamat_kel: '',
        alamat_kec: '',
        alamat_prov: '',
        alamat_kota: '',
        no_hp: ''
      },
      options: {
        jenis_pelayanan: [
          { name: 'Ruang Rawat Inap', value: 1 },
          { name: 'Ruang Rawat Jalan', value: 2 }
        ],
        status: [
          { name: 'Proses Verifikasi', value: 1 },
          { name: 'Pending Verifikasi', value: 2 },
          { name: 'Klaim', value: 3 }
        ]
      },
      selected: {
        jeniskelamin: [],
        stsNikah: []
      },
      config: {
        dateFormat: 'Y-m-d',
        maxDate: 'today',
        disableMobile: true
      },
      search: {
        tanggal_awal: '',
        tanggal_akhir: '',
        searchLike: '',
        flag: ''
      }
    }
  },
  mounted () {
    xray.index()
    this.list()
  },
  methods: {
    async list () {
      try {
        const response = await Services.getDataAllSihepi()
        return response.data.response.klaim
      } catch (error) {
        this.table.isBusy = false
        this.status = 400
        this.message = error.message
      }
    },
    refreshTable () {
      this.$refs.table.refresh()
    },
    cari () {
      this.$refs.table.refresh()
    }
  }
}
</script>
<style>
#bpjs .table td {
  font-size: 12px;
  font-weight: 500;
  padding-top: 0px;
  padding-bottom: 0px;
}

#bpjs .table th {
  font-size: 13px;
  font-weight: 400;
}

#bpjs .page-link {
  background: transparent !important;
  border: none !important;
  color: gray !important;
  margin-right: 15px;
}

#bpjs .page-item.active .page-link {
  background-color: transparent;
  border-color: transparent;
  color: #089bab !important;
  font-weight: bold;
}
#bpjs .my-cursor {
  cursor: pointer;
}
#bpjs .radius-custom {
  border-radius: .3rem;
}
#bpjs .form-control-custom {
  height: 33px!important;
  background-color: transparent;
  border-radius: .3rem!important;
  font-size: 13px;
  color: black;
}
#bpjs .multiselect__placeholder {
  font-weight: normal;
  font-size: 13px!important;
  color: #495057c9;
}
#bpjs .multiselect__option {
  font-weight: normal;
  font-size: 13px;
}
#bpjs .multiselect__tags {
  overflow: hidden;
  font-weight: normal;
  padding: 3px 40px 0 8px!important;
  height: 33px!important;
  min-height: 33px!important;
  border: 1px solid rgba(0, 0, 0, 0.212);
}
#bpjs .multiselect {
  height: 33px!important;
  min-height: 33px!important;
}
#bpjs .multiselect__select {
  height: 33px!important;
  min-height: 33px!important;
}
#bpjs .multiselect__single  {
  padding-left: 0px;
}
#bpjs .custom-fonts {
  font-size: 13px;
}
#bpjs .custom-flat-pickr {
    border: 1px solid rgb(233, 233, 233);
    height: 33px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid rgb(192, 192, 192)!important;
    border-radius: 5px;
    font-size: 12px;
    color: black;
}
</style>
